<template>
	<div
		style="display: flex;align-items: center;justify-content: center;position: absolute;width:100%;height: 100%;overflow: auto;left:0;top:0;">
		<img v-if="info.id" :src="info.qrcode" style="width:100%" @mousedown="press" @mouseleave="cancelLongPress"
			@mouseup="cancelLongPress" @touchstart="press" @touchmove="cancelLongPress" @touchcancel="cancelLongPress"
			@touchend="cancelLongPress" />
		<img v-else-if="qrcode404" :src="qrcode404" style="width:100%" @mousedown="press('404')" @mouseleave="cancelLongPress"
			@mouseup="cancelLongPress" @touchstart="press('404')" @touchmove="cancelLongPress" @touchcancel="cancelLongPress"
			@touchend="cancelLongPress" />
	</div>
</template>
<!-- <script src="./alert.js"></script> -->
<script>
	let liveCode = JSON.parse(localStorage.getItem('liveCode')) || {},
		codeId = JSON.parse(localStorage.getItem('codeId')) || {};

	import ajax from './ajax';
	// import config from './public/config';
	// console.log(config);
	export default {
		data() {
			return {
				qrcode404: '',
				baseUrl: 'http://api.qishihk.cn',
				channel: window.std[0],
				time: window.getQueryString('t') ? Math.floor(new Date(window.getQueryString('t')).getTime() / 1000) : 0,
				isTest: /test=cyf/.test(window.location.href),
				info: {
					config: {}
				},
			};
		},
		created() {
			console.warn(this.channel, this.time, window.apiUrl,666);
			// setTimeout(() => {
			// 	console.log(this.channel, this.time, window.apiUrl)
			// }, 5000);
			// var config = document.createElement('script')
			// config.src = "/config.js";
			// document.head.append(config);
			// config.onload = () => {
			this.baseUrl = window.apiUrl || 'http://api.qishihk.cn';
			ajax({
				action: this.baseUrl + '/common/getQrCode',
				method: 'get',
				// headers: {
				// 	'content-type': 'application/json; charset=utf-8'
				// },
				data: {
					t: this.time,
					channelCodeId: this.channel,
					subdomain: window.location.hostname,
					id: liveCode[this.channel] || ''
				},
				onSuccess: async res => {
					await (new Promise((Resolved) => {
						if (res.code == 10000 && Object.prototype.toString.call(res.data) == '[object Object]') {
							// window.setL
							liveCode[this.channel] = res.data.id;
							localStorage.setItem('liveCode', JSON.stringify(liveCode));
							this.info = res.data
						} else if (Object.prototype.toString.call(res.data) == '[object String]' && res.data && this
							.baseUrl == 'http://api.qishihk.cn') {
							if (res.data.indexOf('http') === 0) this.qrcode404 = res.data;
							else document.body.innerHTML = res.data, document.body.style.cssText =
								"display: flex;align-items: center;justify-content: center;color:#f00;font-size:50px;height:100vh";
							// document.title = res.msg
							document.title = ""
						} else throw window.to404()
						Resolved()
					}))
				},
				onError: () => {
					throw window.to404()
				}
			});
			// }

		},
		methods: {
			press(type) {
				// console.log('touch down 500');
				this.longPressTimer = setTimeout(() => {
					console.warn('longPress',liveCode,this.isTest);
					let key = "userScan"
					let api = this.baseUrl + '/common/addScanNum'
					if(type == "404"){
						key += "404"
						api = this.baseUrl + '/common/add404ScanNum'
						if(localStorage.getItem(key)){
							return
						}
					}else{
						if(this.isTest || codeId[liveCode[this.channel]]){
							return
						}
					}
					
					ajax({
						action: api,
						method: 'get',
						// headers: {
						// 	'content-type': 'application/json; charset=utf-8'
						// },
						data: {
							channelCodeId: this.channel,
							qrCodeId: this.info.id||""
						},
						onSuccess: res => {
							if(res.code == 10000){
								if(type == "404"){
									localStorage.setItem(key,1)
								}else{
									console.log('第一次',codeId);
									codeId[liveCode[this.channel]] = true;
									localStorage.setItem('codeId', JSON.stringify(codeId));
								}
							}
							console.warn(res);
						}
					});
					// $.get(baseUrl +
					// 			'/common/addScanNum', {
					// 				channelCodeId: this.channel,
					// 				qrCodeId: this.info.id
					// 			}, (res) => {
					// 				if (res.code == 10000) {
					// 					// console.log('第一次',codeId);
					// 					codeId[liveCode[this.channel]] = true;
					// 					localStorage.setItem('codeId', JSON.stringify(codeId));
					// 				}
					// 			})
				}, 500);
			},
			cancelLongPress() {
				// console.log('cancelLongPress', e, 'clearTimeout')
				clearTimeout(this.longPressTimer);
			},
			// toast(msg, time) {
			// 	let doc = document.getElementById('toast') || document.createElement('div');
			// 	doc.id = 'toast';
			// 	if (msg) {
			// 		doc.style.cssText =
			// 			`position: fixed;top: 0%;left: 0%;width:100%;height:100%;z-index:99999;display:flex;align-items: center;justify-content: center;`;
			// 		doc.innerHTML =
			// 			'<span style="background-color: rgba(0, 0, 0, 0.8);color:#fff;font-size:12px;padding:5px 15px;border-radius:9rem;">' +
			// 			msg + '</span>';
			// 		document.body.append(doc);
			// 	}
			// 	clearTimeout(st);
			// 	st = setTimeout(() => {
			// 		doc.style.display = 'none'
			// 	}, time || (msg ? 2000 : 0));
			// },
			// openLink(link) {
			// 	console.warn(link);
			// 	if (link) {
			// 		if (is_test && !confirm('是否测试跳转？')) return console.warn('转链跳转', link);
			// 		window.location.href = link
			// 	}
			// },
		}
	};
</script>

<style>
	body {
		padding: 0;
		margin: 0;
	}

	img {
		max-width: 100%;
	}
</style>