function getError(action, option, xhr) {
	let msg;
	if (xhr.response) {
		msg = `${xhr.response.error || xhr.response}`;
	} else if (xhr.responseText) {
		msg = `${xhr.responseText}`;
	} else {
		msg = `fail to post ${action} ${xhr.status}`;
	}

	const err = new Error(msg);
	err.status = xhr.status;
	err.method = 'post';
	err.url = action;
	return err;
}

function getBody(xhr) {
	const text = xhr.responseText || xhr.response;
	if (!text) {
		return text;
	}

	try {
		return JSON.parse(text);
	} catch (e) {
		return text;
	}
}

function buildParamsStr(paramsObj) {
	var str = '';
	for (var key in paramsObj) {
		// 对查询字符串中每个参数名称和值用encdoeURIComponent()进行编码
		str += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(paramsObj[key]);
	}
	return str.slice(1);
}

export default function upload(option) {
	if (typeof XMLHttpRequest === 'undefined') {
		return;
	}

	const xhr = new XMLHttpRequest();

	let formData;
	if (option.filename && option.file) {
		formData = new FormData();
		if (option.data) {
			Object.keys(option.data).forEach(key => {
				formData.append(key, option.data[key]);
			});
		}

		formData.append(option.filename, option.file, option.file.name);
	} else formData = buildParamsStr(option.data)

	if (option.method === 'get' && formData) {
		option.action += (option.action.indexOf('?') === -1 ? '?' : '&') + formData;
	}
	const action = option.action;

	if (xhr.upload && option.onProgress) {
		xhr.upload.onprogress = function progress(e) {
			if (e.total > 0) {
				e.percent = e.loaded / e.total * 100;
			}
			option.onProgress(e);
		};
	}



	xhr.onerror = function error(e) {
		option.onError(e);
	};

	xhr.onload = function onload() {
		if (xhr.status < 200 || xhr.status >= 300) {
			return option.onError(getError(action, option, xhr));
		}

		option.onSuccess(getBody(xhr));
	};

	xhr.open(option.method, action, true);

	if (option.withCredentials && 'withCredentials' in xhr) {
		xhr.withCredentials = true;
	}

	if (option.method == 'get') {
		xhr.send(null); //发送请求
	} else {
		const headers = option.headers || {};

		for (let item in headers) {
			if (headers.hasOwnProperty(item) && headers[item] !== null) {
				xhr.setRequestHeader(item, headers[item]);
			}
		}
		// xhr.setRequestHeader('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
		xhr.send(formData);
	}
	return xhr;
}